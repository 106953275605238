import * as React from "react"
import SEO from "components/seo"
import { BlogT, PageContextT, PageT } from "../types/Common"
import Layout from "../components/organisms/Layout"
import BlogDetail from "../components/organisms/BlogDetail/BlogDetail"
import { PageProps } from "gatsby"
import { useTranslation } from "react-i18next"

type PropsT = PageContextT<BlogT>

const IndexPage: React.FC<PageProps<null, PropsT, null>> = props => {
  const {
    pageContext: {
      page,
      mainHeaderLinks,
      otherHeaderLinks,
      navigationLinks,
      footerSections,
      socialLinks,
      commonLinks,
      blogs,
      configurations,
      mobileHeaderLinks,
    },
    location,
  } = props
  const { i18n } = useTranslation()
  const breadcrumbs = [
    {
      title: "Blog",
      route: i18n.language === "en" ? "/en/blog" : "/blog",
    },
    {
      title: page.title,
    },
  ]

  return (
    <Layout
      mainHeaderLinks={mainHeaderLinks}
      otherHeaderLinks={otherHeaderLinks}
      navigationLinks={navigationLinks}
      footerSections={footerSections}
      socialLinks={socialLinks}
      commonLinks={commonLinks}
      breadcrumbs={breadcrumbs}
      configurations={configurations}
      mobileHeaderLinks={mobileHeaderLinks}
      breadCrumbs={["/blog", "/en/blog"]}
    >
      <SEO
        title={page.meta?.title || page.title}
        description={page.meta?.description}
        image={page.meta?.image}
      />
      {/* TODO - fix article type */}
      <BlogDetail
        article={page}
        blogs={blogs}
        location={location}
        configurations={configurations}
      />
    </Layout>
  )
}

export default IndexPage

import React from 'react';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';

type Props = {
  images?: object
  isAutoPlaying?: boolean
  autoPlayInterval?: number
  isLoop?: boolean
  objectFit?: string
  objectFitAtMax?: string
  shouldLazyLoad? :boolean
  style?: any
}
export const PhotoSwipe: React.FC<Props> = ({
  isAutoPlaying,
  autoPlayInterval,
  objectFitAtMax,
  objectFit,
  isLoop,
  shouldLazyLoad,
  style,
  images
}) => {
  return (
     <Carousel
       images={images}
       isAutoPlaying={isAutoPlaying}
       autoPlayInterval={autoPlayInterval}
       isLoop={isLoop}
       objectFit={objectFit}
       objectFitAtMax={objectFitAtMax}
       shouldLazyLoad={shouldLazyLoad}
       style={style}
     />
  )
}

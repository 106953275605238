import Box from "components/molecules/Box"
import React from "react"
import { BlogT } from "types/Common"
import BlogItemInfo from "../BlogList/BlogItemInfo"

type PropsT = {
  article: BlogT
}

const BlogPreviewItem: React.FunctionComponent<PropsT> = ({ article }) => {
  return (
    <Box link={`/blog/${article.slug}`}>
      <h3 className="mb-4 text-2xl text-quantcomBlue font-bold">
        {article.title}
      </h3>
      <BlogItemInfo
        created={article.blogPublishedAt}
        category={article.category}
        publishedAt={article.blogPublishedAt}
        isBanner
        isDark
        perex={article.perex}
        text={article.text}

      />
    </Box>
  )
}

export default BlogPreviewItem

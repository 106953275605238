import React from "react"
import Button from "../../atoms/Button"
import { IconButton } from "../../atoms/IconButton"
import styled from "styled-components"
import { BlogCategoryT } from "types/Common"
import { useTranslation } from "react-i18next"

type PropsT = {
  location: Location
}

const Li = styled.li`
  margin-bottom: 1.05em;
  width: calc(33.333% - 1.05em);
`

const BlogDetailFooter = ({ location }: PropsT) => {
  const { t } = useTranslation()
  return (
    <div className="mt-12 mb-10 pt-10 border-t border-gray-300 flex items-center justify-end flex-wrap">
      <div className="flex items-center w-full sm:w-auto mt-5 sm:mt-0">
        <span>{t("shareArticle")}</span>
        <ul className="ml-5 flex items-center">
          <li className="mr-3 sm:mr-5">
            <IconButton
              icon="facebook-invert"
              link={`https://www.facebook.com/sharer/sharer.php?u=${location.href}`}
              size="text-xl"
              color="inherit"
              type="outline"
              target="_blank"
            />
          </li>
          <li className="mr-3 sm:mr-5">
            <IconButton
              icon="linkedin-invert"
              link={`https://www.linkedin.com/sharing/share-offsite/?url=${location.href}`}
              size="text-xl"
              color="inherit"
              type="outline"
              target="_blank"
            />
          </li>
          <li>
            <IconButton
              icon="twitter-invert"
              link={`http://twitter.com/share?url=${location.href}`}
              size="text-xl"
              color="inherit"
              type="outline"
            />
          </li>
        </ul>
      </div>
    </div>
  )
}

export default BlogDetailFooter

import BlockTitle from "components/atoms/BlockTitle"
import React from "react"
import { BlogsT, BlogT, ConfigurationT, PageT } from "types/Common"
import Block from "../../atoms/Block"
import WysiwygRenderer from "../../atoms/WysiwygRenderer"
import BlogDetailFooter from "./BlogDetailFooter"
import BlogPreview from "../BlogPreview/BlogPreview"
import FollowUs from "../BlockFollowUs"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { getConfiguration } from "utilities/configuration"
import { PhotoSwipe } from "../../molecules/PhotoSwipe"
import Button from "../../atoms/Button"
import Banner from "../../molecules/Banner"
import BlogItemInfo from "../BlogList/BlogItemInfo"
import { getImageFile } from "../../../utilities/Image"

type PropsT = {
  article: PageT & BlogT
  blogs: BlogsT
  location: Location
  configurations: ConfigurationT[]
}

const Title = styled(BlockTitle)`
  margin: 0.9em 0 0.6em;
`

const Perex = styled.p`
  margin-bottom: 2em;
`

const BlogDetail: React.ComponentType<PropsT> = ({
  article,
  blogs,
  location,
  configurations,
}) => {
  const suggested = blogs.filter(blog => blog.id !== article.id).slice(0, 3)
  const { t } = useTranslation()

  const facebookLink = getConfiguration(configurations, "SOCIAL_FACEBOOK_LINK")
  const linkedInLink = getConfiguration(configurations, "SOCIAL_LINKEDIN_LINK")
  const instagramLink = getConfiguration(configurations, "SOCIAL_INSTAGRAM_LINK")
  const twitterLink = getConfiguration(configurations, "SOCIAL_TWITTER-INVERT_LINK")

  const images = article.gallery.map(img => ({
    src: getImageFile(img?.id, "original_webp", img?.formats),
  }))

  const imageDesktop = article.coverImage
  const imageTablet = article.coverImageTablet
  const imageMobile = article.coverImageMobile

  const wysiwygContent = article.text
    .replaceAll(
      "<oembed url=",
      '<iframe frameborder="0" allowfullscreen="allowfullscreen" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" title="Embedded youtube" style="top: 0; left: 0; height: 100%; width: 100%; position: absolute;" src='
    )
    .replaceAll("</oembed>", "</iframe>")
    .replaceAll(
      '<figure class="media">',
      "<div style='overflow: hidden; position: relative; max-width: 100%; height: 500px;'>"
    )
    .replaceAll("</figure>", "</div>")
    .replaceAll("watch?v=", "embed/")

  return (
    <>
      <Banner
        title={{
          title: article.title,
          type: "h1",
          tag: "h2",
        }}
        wideText={true}
        background={imageDesktop}
        backgroundTablet={imageTablet}
        backgroundMobile={imageMobile}
      >
        <BlogItemInfo
          created={article.created_at}
          author={article.author}
          category={article.category}
          publishedAt={article.blogPublishedAt}
          text={article.text}
          perex={article.perex}
          isBanner
          iconColor={"text-white"}
        />
      </Banner>

      <Block size="sm">
        <div className={"flex flex-row-reverse"}>
          <Button className={"pointer-events-none"} size="sm" type="primary">
            {article.category.name}
          </Button>
        </div>
        <Perex className="mt-16 text-xl sm:text-2xl">{article.perex}</Perex>
        <WysiwygRenderer content={wysiwygContent} />
        {article.gallery != null && article.gallery.length > 0 && (
          <div className={"flex justify-center"}>
            <PhotoSwipe
              images={images}
              isAutoPlaying={true}
              autoPlayInterval={2500}
              isLoop={true}
              objectFit={"contain"}
              objectFitAtMax={"contain"}
              style={{ height: 500, width: 800 }}
            />
          </div>
        )}
        <BlogDetailFooter location={location} />
      </Block>

      <Block background="gray">
        <BlockTitle
          type="h1"
          tag="h2"
          title={t("otherInterests")}
          mobileAlign="left"
        />
        <BlogPreview blogs={suggested} />
      </Block>

      <FollowUs facebookLink={facebookLink} linkedInLink={linkedInLink} instagramLink={instagramLink} twitterLink={twitterLink} />
    </>
  )
}

export default BlogDetail

import React from "react"
import { BlogsT } from "types/Common"
import Grid from "../../PageBuilder/Common/Grid"
import Button from "../../atoms/Button"
import BlogPreviewItem from "./BlogPreviewItem"
import { useTranslation } from "react-i18next"

type PropsT = {
  blogs: BlogsT
}

const BlogPreview = ({ blogs }: PropsT) => {
  const { t, i18n } = useTranslation()
  return (
    <div>
      <Grid md={2} lg={3} gap="0" gapSm="1rem 5.9%">
        {blogs.map(article => (
          <BlogPreviewItem key={article.id} article={article} />
        ))}
      </Grid>
      <div className="mt-14 text-center">
        <Button
          iconLeft="arrow-left-short"
          iconAnimated
          link={i18n.language === "en" ? "/en/blog" : "/blog"}
        >
          {t("backToList")}
        </Button>
      </div>
    </div>
  )
}

export default BlogPreview
